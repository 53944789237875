import _parse from "./lib/parse";
import _audit from "./lib/audit";
import _groups from "./lib/groups";
var exports = {};
const parse = _parse;
const audit = _audit;
exports = {
  parse,
  audit,
  hyphenate: val => {
    const data = parse(val);
    if (!data) return null;
    return data.isIsbn13 ? data.isbn13h : data.isbn10h;
  },
  asIsbn13: (val, hyphen) => {
    const data = parse(val);
    if (!data) return null;
    return hyphen ? data.isbn13h : data.isbn13;
  },
  asIsbn10: (val, hyphen) => {
    const data = parse(val);
    if (!data) return null;
    // Return null for cases where it shouldn't map to an ISBN 10
    // ex: 979-10-91146-13-5
    if (!data.isbn10) return null;
    return hyphen ? data.isbn10h : data.isbn10;
  },
  groups: _groups
};
export default exports;
export const hyphenate = exports.hyphenate;
const _parse2 = exports.parse,
  _audit2 = exports.audit;
export { _parse2 as parse, _audit2 as audit };